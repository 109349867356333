import { inject, Injectable } from '@angular/core';
import { type ActivatedRouteSnapshot, type Resolve } from '@angular/router';
import { JsonApiClientService, type ResourceTypes as JsonApiResourceTypes } from '@big-direkt/json-api-client';
import { SectionLocksService, type TranslatedPathModel } from '@big-direkt/utils/environment';
import { type NodeModel } from '@big-direkt/utils/shared';
import { type Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ContentMapperService } from './content-mapper.service';
import { NodeInformationService } from './node-information.service';
import { PathTranslatorService } from './path-translator.service';

const PARAM_JOB_OFFER_ID = 'job-offer-id';
const PARAM_EXTERNAL_TOKEN = 'externalToken';
const PARAM_REVISION = 'revision';

@Injectable({
    providedIn: 'root',
})
export class ContentResolverService implements Resolve<Observable<NodeModel>> {
    private readonly contentMapper = inject(ContentMapperService);
    private readonly pathTranslator = inject(PathTranslatorService);
    private readonly jsonApiClient = inject(JsonApiClientService);
    private readonly sectionLocksService = inject(SectionLocksService);
    private readonly nodeInformationService = inject(NodeInformationService);

    public resolve(route: ActivatedRouteSnapshot): Observable<NodeModel> {
        const formattedPath = `/${route.pathFromRoot
            .flatMap(value => value.url)
            .map(value => value.path)
            .join('/')}`;

        return this.pathTranslator.translate(formattedPath).pipe(
            switchMap((translatedPath: TranslatedPathModel): Observable<TranslatedPathModel> => {
                if (translatedPath.maintenanceTags?.length === 0) {
                    return of(translatedPath);
                }

                return this.sectionLocksService.getBySectionIds(translatedPath.maintenanceTags ?? []).pipe(
                    map(sectionItems => {
                        if (sectionItems.length === 0) {
                            return translatedPath;
                        }

                        const {
                            errorNode: { type, uuid },
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        } = sectionItems.shift()!;

                        return {
                            type,
                            uuid,
                            maintenanceTags: translatedPath.maintenanceTags,
                        };
                    }),
                );
            }),
            switchMap((translatedPath: TranslatedPathModel): Observable<unknown> => {
                const params = this.forwardQueryParams(route);

                return this.jsonApiClient.load(translatedPath.uuid, translatedPath.type as JsonApiResourceTypes, params);
            }),
            map(this.contentMapper.map.bind(this.contentMapper)),
            tap(node => { this.nodeInformationService.setNodeType(node.type); }),
            shareReplay({
                refCount: true,
            }),
        );
    }

    private forwardQueryParams(route: ActivatedRouteSnapshot): Record<string, number | string> {
        const params: Record<string, number | string> = {};

        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        if (route.queryParamMap.has(PARAM_REVISION)) {
            params.resourceVersion = `id:${parseInt(route.queryParamMap.get(PARAM_REVISION)!, 10)}`;
        }

        if (route.queryParamMap.has(PARAM_EXTERNAL_TOKEN)) {
            params[PARAM_EXTERNAL_TOKEN] = route.queryParamMap.get(PARAM_EXTERNAL_TOKEN)!;
        }

        if (route.queryParamMap.has(PARAM_JOB_OFFER_ID)) {
            params[PARAM_JOB_OFFER_ID] = route.queryParamMap.get(PARAM_JOB_OFFER_ID)!;
        }
        /* eslint-enable @typescript-eslint/no-non-null-assertion */

        return params;
    }
}
