import { Injectable, signal } from '@angular/core';
import { NodeTypes } from '@big-direkt/utils/shared';

@Injectable({ providedIn: 'root' })
export class NodeInformationService {

    public nodeType = signal<NodeTypes | undefined>(undefined);

    public setNodeType(type: NodeTypes): void {
        this.nodeType.set(type);
    }
}
