import { Injectable } from '@angular/core';
import { type ParagraphAccordionItemModel, type ParagraphAccordionModel, type NodeModel, NodeTypes, ParagraphTypes } from '@big-direkt/utils/shared';

@Injectable({ providedIn: 'root' })
export class ContentMapperService {
    public map(content: unknown): NodeModel {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const type: string = (content as any).type as string;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (type === ParagraphTypes.Accordion) {
            return this.mapAccordion(content as ParagraphAccordionModel);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (type === ParagraphTypes.AccordionItem) {
            return this.mapAccordionItem(content as ParagraphAccordionItemModel);
        }

        return content as NodeModel;
    }

    private mapAccordion(accordion: ParagraphAccordionModel, customTitle?: string): NodeModel {
        return {
            type: NodeTypes.Article,
            nodeId: accordion.nodeId,
            heading: customTitle ?? accordion.title,
            paragraphs: [accordion],
            pageTitle: {},
        };
    }

    private mapAccordionItem(accordionItem: ParagraphAccordionItemModel): NodeModel {
        const accordion: ParagraphAccordionModel = {
            type: ParagraphTypes.Accordion,
            nodeId: accordionItem.nodeId,
            title: '',
            items: [accordionItem],
        };

        return this.mapAccordion(accordion, accordionItem.title);
    }
}
