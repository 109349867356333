import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ResourceTypes as JsonApiResourceTypes } from '@big-direkt/json-api-client';
import { RestApiClientService, ResourceTypes as RestApiResourceTypes } from '@big-direkt/rest-api-client';
import { EnvironmentService, type TranslatedPathModel } from '@big-direkt/utils/environment';
import { TranslocoService } from '@jsverse/transloco';
import { of, throwError, type Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PathTranslatorService {
    private readonly environmentService = inject(EnvironmentService);
    private readonly translocoService = inject(TranslocoService);
    private readonly restApiClientService = inject(RestApiClientService);

    public translate(path: string): Observable<TranslatedPathModel> {
        return this.restApiClientService.load<TranslatedPathModel>(RestApiResourceTypes.RouterTranslatePath, path).pipe(
            switchMap(res =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                res.type === JsonApiResourceTypes.NodeQuickLink
                    ? throwError(
                        () =>
                            new HttpErrorResponse({
                                status: HttpStatusCode.NoContent,
                            }),
                    )
                    : of(res),
            ),
            catchError(this.handleError.bind(this)),
        );
    }

    private handleError(err: HttpErrorResponse): Observable<TranslatedPathModel> {
        const notFoundNode = this.environmentService.errorNodeIds?.notFound;
        const lang = this.translocoService.getActiveLang();

        if (!notFoundNode?.de) {
            throw new Error('No node for errors was provided in environment.ts');
        }

        let nodeUUID = notFoundNode.de;

        if (notFoundNode[lang]) {
            nodeUUID = notFoundNode[lang];
        }

        return of({
            type: err.error?.jsonapi?.resourceName ?? JsonApiResourceTypes.NodeError,
            uuid: err.error?.entity?.uuid ?? nodeUUID,
            maintenanceTags: [],
        });
    }
}
